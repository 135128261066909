body {
  background-color: rgb(41, 41, 41);
  color: rgb(211, 199, 199);
}

/* Common */
.column.quarter {
  width: 25%;
}

.column.half {
  width: 50%;
}

.column.three-quarter {
  width: 75%;
}

.App .row {
  height: 100vh;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.right {
  float: right;
}

.left {
  float: left;
}

/* SplashScreen.js */
.SplashScreen--container {
  text-align: center;
  height: 100vh;
}

.SplashScreen--title {
  font-size: 32px;
  margin: auto;
}

/* Decision.js */
.Decision--container {
  height: auto;
  position: fixed;
}

.Decision--title {
  width: 100%;
  text-align: center;
}

.Decision--display {
  text-align: center;
  font-size: 24px;
}

/* DecideButton.js */
.DecideButton-container {
  position: fixed;
  bottom: 0;
}

.DecideButton-container:after {
  content: "";
  display: table;
  clear: both;
}

.DecideButton {
  align-items: center;
  background-color: #0A66C2;
  border: 4px solid #0A66C2;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 32px;
}

.ClearButton {
  align-items: center;
  background-color: transparent;
  border: 4px solid #0A66C2;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 32px;
}

.DecideButton:hover,
.DecideButton:focus,
.ClearButton:hover,
.ClearButton:focus {
  background-color: #16437E;
  color: #ffffff;
  border: 4px solid #16437E;
}

.DecideButton:active,
.ClearButton:active {
  background: #09223b;
  color: rgb(255, 255, 255, .7);
  border: 4px solid #09223b;
}

.DecideButton:disabled,
.ClearButton:disabled {
  cursor: not-allowed;
  background: rgba(0, 0, 0, .08);
  border: 4px solid rgba(0, 0, 0, .08);
  color: rgba(0, 0, 0, .3);
}